import type { CSSProperties } from 'react'
import { useEffect, useRef, useState } from 'react'
import {
  animated,
  config,
  useChain,
  useSpring,
  useSpringRef,
  useTransition
} from '@react-spring/web'
import { breakpoints, projects } from '@utils/constants'
import useBreakpoint from 'use-breakpoint'
import useOnClickOutside from '@hooks/useOnClickOutside'
import LatestWorkCard from './Card'
import ShowCase from './ShowCase'

export default function Gallery() {
  const containerRef = useRef<HTMLDivElement | null>(null)
  const { breakpoint } = useBreakpoint(breakpoints, 'largeDesktop')
  const [open, setOpen] = useState(false)
  const parentSpringApi = useSpringRef()
  const parentSpringApiStyles = useSpring({
    ref: parentSpringApi,
    config: config.stiff,
    from: {
      width:
        (breakpoint === 'tablet') ? '325px' : (breakpoint === 'mobile') ? '90%' : '373px',
      maxWidth: (breakpoint === 'mobile') ? '344px' : '',
      height: (breakpoint === 'tablet' || breakpoint === 'mobile') ? '225px' : '273px',
      right: (breakpoint === 'mobile') ? '50%' : '0%',
      borderRadius: (breakpoint === 'tablet') ? '13px' : '15px',
      top: (breakpoint === 'mobile') ? '56%' : '0%',
      paddingBottom: (open && breakpoint === 'mobile') ? '3rem' : '',
      position: 'absolute',
      transform: (breakpoint === 'mobile') ? 'translateX(50%)' : 'translateX(0%)',
      zIndex: '1',
      background: '#fff',
    },
    to: {
      width: open
        ? (breakpoint === 'tablet' || breakpoint === 'desktop')
          ? '741px'
          : (breakpoint === 'mobile')
            ? '90%'
            : '1200px'
        : (breakpoint === 'tablet')
          ? '325px'
          : (breakpoint === 'mobile')
            ? '90%'
            : '373px',
      maxWidth: (open && breakpoint === 'mobile') ? '344px' : '',
      height: open
        ? (breakpoint === 'tablet' || breakpoint === 'desktop')
          ? '644px'
          : (breakpoint === 'mobile')
            ? 'auto'
            : '740px'
        : (breakpoint === 'tablet' || breakpoint === 'mobile')
          ? '225px'
          : '273px',
      borderRadius: open
        ? '25px'
        : (breakpoint === 'tablet')
          ? '13px'
          : '15px',
      right: open ? '50%' : (breakpoint === 'mobile') ? '50%' : '0%',
      top: open
        ? (breakpoint === 'tablet' || breakpoint === 'desktop')
          ? '0%'
          : (breakpoint === 'extraLargeDesktop') ? '70%' : '0%'
        : (breakpoint === 'mobile') ? '56%' : '0%',
      paddingBottom: (open && breakpoint === 'mobile') ? '3rem' : '',
      position: 'absolute',
      transform: open ? 'translateX(50%)' : (breakpoint === 'mobile') ? 'translateX(50%)' : 'translateX(0%)',
      zIndex: open ? '10' : '1',
      background: '#fff',

    }
  })

  const springApi = useSpringRef()
  const { height, w, ...rest } = useSpring({
    ref: springApi,
    config: config.stiff,
    from: { height: '0px', w: '0px', background: 'white', display: 'flex' },
    to: {
      height: open ? (breakpoint === 'tablet' || breakpoint === 'desktop') ? '478px' : '580px' : '0px',
      w: open ? (breakpoint === 'desktop' || breakpoint === 'tablet') ? '734px' : '1114px' : '0px',
      background: open ? 'white' : 'white',
      display: open ? 'grid' : 'none',
      gridTemplateColumns: (breakpoint === 'desktop' || breakpoint === 'tablet' || breakpoint === 'mobile') ? 'repeat(2, minmax(100px, 1fr))' : ' repeat(3, minmax(100px, 1fr))',
      // flexDirection: open ? 'row' : 'row wrap',
      // willChange: ' width, height',
      gap: (breakpoint === 'mobile') ? '3rem 3rem' : '3rem 1rem',
      justifyContent: (breakpoint === 'mobile') ? 'center' : 'center',
      paddingTop: (breakpoint === 'mobile') ? '15px' : (breakpoint === 'tablet' || breakpoint === 'desktop') ? '20px' : '28px',
      borderRadius: open ? '0px 0px 25px 25px' : '15px 15px 15px 15px',
      paddingInline: open ? (breakpoint === 'mobile') ? '0' : (breakpoint === 'tablet' || breakpoint === 'desktop') ? '1.5rem' : '0' : '0',
      marginInline: 'auto',

    }
  })

  const headerSpringApi = useSpringRef()
  const headerSpringApiStyles = useSpring({
    ref: headerSpringApi,
    config: config.stiff,
    from: {
      height: '0px',
      opacity: 0,
      y: 80,
      padding: (breakpoint === 'tablet' || breakpoint === 'desktop')
        ? '20px'
        : (breakpoint === 'mobile')
          ? '20px'
          : '26px 37px',
      background: 'white'
    },
    to: {
      height: open
        ? (breakpoint === 'tablet' || breakpoint === 'desktop')
          ? '60px'
          : breakpoint === 'mobile'
            ? '50px'
            : '70px'
        : '0px',
      opacity: open ? 1 : 0,
      y: open ? 0 : 80,
      padding: open
        ? (breakpoint === 'tablet' || breakpoint === 'desktop')
          ? '20px'
          : (breakpoint === 'mobile')
            ? '20px'
            : '26px 37px'
        : (breakpoint === 'tablet' || breakpoint === 'desktop')
          ? '20px'
          : (breakpoint === 'mobile')
            ? '20px'
            : '26px 37px',
      paddingBottom: '0px',
      background: 'white'
    }
  })
  const showCardSpringApi = useSpringRef()
  const showCardSpringApiStyles = useSpring({
    ref: showCardSpringApi,
    config: config.stiff,
    from: {
      height:
        (breakpoint === 'tablet' || breakpoint === 'mobile' || breakpoint === 'desktop') ? '225px' : '273px',
      opacity: 1,
      y: 80,
      display: 'block'
    },
    to: {
      height: open
        ? '0'
        : (breakpoint === 'tablet' || breakpoint === 'mobile' || breakpoint === 'desktop')
          ? '225px'
          : '273px',
      opacity: open ? 0 : 1,
      y: open ? 0 : 80,
      display: open ? 'none' : 'block'
    }
  })
  const showAllSpringApi = useSpringRef()
  const showAllSpringApiStyles = useSpring({
    ref: showAllSpringApi,
    config: config.stiff,
    from: {
      transform: 'scale(0)',
      opacity: '0',
      display: 'none'
    },
    to: {
      transform: open ? 'scale(1)' : 'scale(0)',
      opacity: open ? '1' : '0',
      display: open ? 'block' : 'none'
    }
  })

  const transApi = useSpringRef()
  const transition = useTransition(open ? projects : [], {
    ref: transApi,
    trail: 700 / projects.length,
    from: { opacity: '0', scale: '0' },
    enter: { opacity: '1', scale: '1' },
    leave: { opacity: '0', scale: '0' }
  })
  useChain(
    open
      ? [
        parentSpringApi,
        showCardSpringApi,
        springApi,
        headerSpringApi,
        transApi,
        showAllSpringApi,
      ]
      : [
        transApi,
        headerSpringApi,
        springApi,
        parentSpringApi,
        showCardSpringApi,
        showAllSpringApi,
      ],
    [
      0,
      open ? 0.3 : 0.6,
    ]
  )
  useEffect(() => {
    if (open) {
      document.body.style.overflow = 'hidden'
      lenis.stop()
      if (containerRef.current) {
        containerRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        })
      }
    }
    else {
      document.body.style.overflowY = 'scroll'
      document.body.style.overflowX = 'hidden'
      lenis.start()
    }
  }, [open])
  const clickOutsidehandler = () => {
    setOpen(false)
  }
  useOnClickOutside(containerRef, clickOutsidehandler)
  return (
    <animated.div
      ref={containerRef}
      onClick={() => setOpen(breakpoint !== 'mobile')}
      style={parentSpringApiStyles as unknown as CSSProperties}
      className='shadow-[0px_0px_19px_rgba(0,0,0,0.12)] bg-white'
    >
      <animated.div
        className="relative -top-5rem "
        style={showCardSpringApiStyles as unknown as CSSProperties}
      >
        <ShowCase />
      </animated.div>

      <animated.div
        style={headerSpringApiStyles}
        className="flex bg-white justify-between items-center rounded-t-25px pb-0"
      >
        <img
          src="/icons/pheno-logo-fade.svg"
          alt="Pheno Agency logo"
          className="w-55px h-50px <md:w-40px <md:h-40px <sm:w-30px <sm:h-30px"
        />
        <button
          onClick={(e) => {
            e.stopPropagation()
            setOpen(false)
          }}

          >
        <img
          src="/icons/close-icon.svg"
          alt="close logo"
          className="hover:scale-150 transform w-40px h-50px cursor-pointer"
          />
          </button>
      </animated.div>
      <animated.div
        className='project-card'
        style={{ ...rest, width: w, height }}>
        {transition((style, item) => {
          return <LatestWorkCard item={item} style={style as unknown as CSSProperties} />
        })}
      </animated.div>
      <animated.div style={showAllSpringApiStyles as unknown as CSSProperties} className='underline-animation w-8rem relative ml-auto top-2rem right-45px <lg:top-4.3rem <lg:right-35px'>
        <a href="/projects" className='flex items-center'>
          <p className='text-hex-0B2864 text-14px font-Montserrat font-bold'>See all projects</p>
          <img
            src="/icons/arrow-right-icon.svg"
            alt="Arrow"
            className="mt-2px <md:w-12px"
          />
        </a>
      </animated.div>
    </animated.div>
  )
}
