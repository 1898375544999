// import React from 'react'

import { breakpoints, projects } from '@utils/constants'
import { useEffect, useState } from 'react'
import useBreakpoint from 'use-breakpoint'

const slicedProjects = projects.slice(0, 4)
export default function ShowCase() {
  const { breakpoint } = useBreakpoint(breakpoints, 'largeDesktop')

  const [current, setCurrent] = useState(1)
  useEffect(() => {
    setInterval(() => {
      setCurrent((prev) => {
        let next = prev + 1
        if (next === 4)
          next = 0

        return next
      })
    }, 3000)
  }, [])
  return breakpoint !== 'mobile'
    ? (
      <div
        className={
          'w-full h-full rounded-15px p-20px pt-20px flex justify-between <md:rounded-13px <md:p-14px <md:pt-17px <sm:w-auto '
        }
      >
        {slicedProjects.map((item, i) => {
          return (
            <div key={i} className="relative h-full">
              <div className={current !== i ? 'z-1 bg-hex-000000 bg-opacity-60 absolute w-30px h-233px rounded-6px <md:h-190px transition-opacity duration-500 ease-in-out delay-400' : 'opacity-0 transition-opacity duration-500 ease-in-out'}></div>
              <img
                src={`/images/${item.showCaseImg}.png`}
                alt="project image"
                className={`relative transition-all duration-500 ease-in-out h-233px <md:h-190px  ${current === i
                  ? 'w-219px rounded-10px object-cover <md:w-185px'
                  : 'w-30px object-none rounded-6px'
                  }`}
              />
              <p className={`z-1 font-Montserrat text-12px font-bold text-white absolute transition-all duration-300 ease-in-out ${current === i ? 'bottom-16px <lg:-bottom-25px left-14px <md:bottom-15px' : i === 0 ? 'bottom-38px -left-9px -rotate-90' : i === 1 ? 'bottom-52px  -left-23px -rotate-90' : i === 2 ? 'bottom-58px -left-30px  -rotate-90' : i === 3 ? 'bottom-28px -left-3px -rotate-90' : ''}`}>{item.title} {current === i ? 'Website' : ''}</p>
            </div>
          )
        })}
      </div>
    )
    : (
      <a
        href='/projects'
        className={
          'w-full h-full rounded-15px p-20px pt-20px flex justify-between <md:rounded-13px <md:p-14px <md:pt-17px <sm:w-auto '
        }
      >
        {slicedProjects.map((item, i) => {
          return (
            <div key={i} className="relative h-full">
              <div className={current !== i ? 'z-1 bg-hex-000000 bg-opacity-60 absolute w-30px h-233px rounded-6px <md:h-190px transition-opacity duration-500 ease-in-out delay-400' : 'opacity-0 transition-opacity duration-500 ease-in-out'}></div>
              <img
                src={`/images/${item.showCaseImg}.png`}
                alt="project image"
                className={`relative transition-all duration-500 ease-in-out h-233px <md:h-190px  ${current === i
                  ? 'w-219px rounded-10px object-cover <md:w-185px'
                  : 'w-30px object-none rounded-6px'
                  }`}
              />
              <p className={`z-1 font-Montserrat text-12px font-bold text-white absolute transition-all duration-300 ease-in-out ${current === i ? 'bottom-16px <lg:-bottom-25px left-14px <md:bottom-15px' : i === 0 ? 'bottom-38px -left-9px -rotate-90' : i === 1 ? 'bottom-52px  -left-23px -rotate-90' : i === 2 ? 'bottom-58px -left-30px  -rotate-90' : i === 3 ? 'bottom-28px -left-3px -rotate-90' : ''}`}>{item.title} {current === i ? 'Website' : ''}</p>
            </div>
          )
        })}
      </a>
    )
}
